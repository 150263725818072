module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":false,"icon":false,"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"byteFaction","short_name":"byteFaction","icons":[{"src":"/android-chrome-36x36.png?v=00aRz4rLGw","sizes":"36x36","type":"image/png"},{"src":"/android-chrome-48x48.png?v=00aRz4rLGw","sizes":"48x48","type":"image/png"},{"src":"/android-chrome-72x72.png?v=00aRz4rLGw","sizes":"72x72","type":"image/png"},{"src":"/android-chrome-96x96.png?v=00aRz4rLGw","sizes":"96x96","type":"image/png"},{"src":"/android-chrome-144x144.png?v=00aRz4rLGw","sizes":"144x144","type":"image/png"},{"src":"/android-chrome-192x192.png?v=00aRz4rLGw","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png?v=00aRz4rLGw","sizes":"256x256","type":"image/png"},{"src":"/android-chrome-384x384.png?v=00aRz4rLGw","sizes":"384x384","type":"image/png"},{"src":"/android-chrome-512x512.png?v=00aRz4rLGw","sizes":"512x512","type":"image/png"},{"src":"/maskable-icon.png?v=00aRz4rLGw","sizes":"1706x1706","type":"image/png","purpose":"maskable"}],"theme_color":"#4a4a4a","background_color":"#ffffff","start_url":"/","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":3}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":3}}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T4NSCW6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://534b85210fdf48cb95a6baa60e899a14@o499064.ingest.sentry.io/5615990","autoSessionTracking":true,"sampleRate":0.7,"attachStackTrace":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
