/* eslint-disable @typescript-eslint/no-explicit-any */

import "prismjs/themes/prism-tomorrow.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";

function initializeAddToAny() {
  if ((window as any).a2a) {
    (window as any).a2a.init_all();
  }
}

function initializeThirdPartyObjects() {
  initializeAddToAny();
}

export const onInitialClientRender = () => {
  initializeThirdPartyObjects();
};

export const onRouteUpdate = () => {
  initializeThirdPartyObjects();
};
